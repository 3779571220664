import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AccountService} from "../services/auth/account.service";
import {SubscribableComponent} from "../utils/subscribable-component";

@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuard extends SubscribableComponent implements CanActivate {

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private accountService: AccountService,
  ) {
    super();
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.oidcSecurityService
      .checkAuth()
      .pipe(catchError((err) => {
        console.log(err)
        return of({isAuthenticated: false})
      }))
      .pipe(map(({isAuthenticated}) => {
        this.accountService.isLogged.next(isAuthenticated)
        if (!isAuthenticated) {
          this.oidcSecurityService.authorize();
        }
        return isAuthenticated;
      }));
  }
}


